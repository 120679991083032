<div class="container">
  <div class="row mb-4 mt-4">
    <div class="col-md-8 u-border-right-grey pr-md-4 pr-lg-5">
      <div class="row">
        <div class="col-md-6">
          <h2 class="font-bold">{{ tabSelected }}</h2>
        </div>
        <div class="col-md-6 d-none d-md-block">
          <ul class="nav nav-tabs d-flex justify-content-end text-fixed-medium">
            <li
              class="nav-item px-3"
              [ngClass]="{ 'nav-Item-Border': tabSelected == 'User Profile' }"
            >
              <p
                (click)="tabNavigation('User Profile', 'User')"
                class="mb-1 pb-1"
                [ngClass]="{ 'tab-Name': tabSelected == 'User Profile' }"
              >
                {{ "profile.user" | cxTranslate }}
              </p>
            </li>
            <li
              class="nav-item"
              [ngClass]="{
                'nav-Item-Border': tabSelected == 'Account information'
              }"
            >
              <p
                class="mb-1 pb-1"
                (click)="tabNavigation('Account information', 'Account')"
                [ngClass]="{ 'tab-Name': tabSelected == 'Account information' }"
              >
                {{ "profile.account" | cxTranslate }}
              </p>
            </li>
          </ul>
        </div>
        <div class="col-md-12 d-block d-md-none mb-4">
          <ng-select
            class="small-Tab-Select"
            [items]="tabDataArray"
            bindLabel="key"
            bindValue="value"
            [multiple]="false"
            name="selecTabVal"
            [clearable]="false"
            placeholder=""
            (change)="tabNavigationDropDown()"
            [(ngModel)]="tabSelected"
          >
          </ng-select>
        </div>
      </div>
      <div class="tab-contents">
        <div id="User">
          <app-user></app-user>
        </div>
        <div id="Account">
          <app-account></app-account>
        </div>
        <!-- <div id="Payment">
                    <app-payment></app-payment>
                </div> -->
      </div>
    </div>
    <div class="col-md-4 pl-md-4 pl-lg-5">
      <app-my-account></app-my-account>
    </div>
  </div>
</div>
