import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CsAgentAuthService } from '@spartacus/asm/root';
import { UserAccountFacade } from '@spartacus/user/account/root';
import { Subscription } from 'rxjs';
import { SharedService } from '../../shared/services/shared.service';
import { CommonUtils } from '../../shared/utils/common.utils';
import { MakeAPaymentService } from '../make-a-payment.service';

@Component({
  selector: 'app-payment-review',
  templateUrl: './payment-review.component.html',
  styleUrls: ['./payment-review.component.scss'],
})
export class PaymentReviewComponent implements OnInit {
  dataType: any;
  sortDir = 1;
  paymentDateDefault = new Date();
  paymentList: any = [
    {
      paymentOption: 'Pay now',
      invoice: '92258122',
      invoiceDate: '17/08/2021',
      dueDate: '18/08/2021',
      invoiceBalance: '200$',
      paymentAmount: '500$',
      paymentMethod: '',
      paymentDate: '19/08/2021',
    },
  ];

  creditPaymentList: any = [];
  invoicePaymentList: any = [];
  formatValue: any = [];
  header: string;
  creditInvoice: string;
  userData: any;
  personName: string;
  selectedCardDetails: any;
  b2bUnitID: any;
  totalInvoiceAmount: any;
  compNameAdBanner1 = 'InvoiceBannerComponent';
  dispCardNumber: any;
  invoiceConfirmation = '/openInvoicePaymentConfirmation';
  FOUR = 4;
  asmEmailDetails: any;
  isAsmEnabled: boolean;
  userSubscription: Subscription;
  accountIdSubscription: Subscription;
  csAgentAuthSubscription: Subscription;
  creditCardSubscription: Subscription;
  saveCreditSubscription: Subscription;
  invoiceSubmitFlag = false;
  creditSubmitFlag = false;
  constructor(
    private readonly makeAPayment: MakeAPaymentService,
    private readonly sharedService: SharedService,
    private readonly router: Router,
    private readonly user: UserAccountFacade,
    private readonly cdr: ChangeDetectorRef,
    private readonly csAgentAuthService: CsAgentAuthService
  ) { }

  ngOnInit(): void {
    localStorage.removeItem('paymentConfirmation');
    localStorage.removeItem('creditConfirmation');
    this.header = 'paymentDetails';
    this.userSubscription = this.user.get().subscribe((res) => {
      if (!res === false) {
        this.userData = res;
        if (this.userData?.userName) {
          this.accountIdSubscription = this.makeAPayment.getAccountId(this.userData?.userName).subscribe((resp) => {
            this.b2bUnitID = resp?.defaultB2BUnit?.uid;
          });
        }
      }
    });
    this.personName = this.sharedService.getSessionStorage('creditPersonName');
    const List = this.sharedService.getSessionStorage('creditData');

    if (List !== null) {
      this.header = 'creditDetails';
      this.creditInvoice = '';
      this.creditPaymentList = List;
    } else {
      this.header = 'paymentDetails';
      this.creditInvoice = 'invoiceTable';
      this.invoicePaymentList =
        this.sharedService.getSessionStorage('invoicesData');
      this.selectedCardDetails =
        this.sharedService.getSessionStorage('selectedCard');
      this.dispCardNumber =
        this.selectedCardDetails?.displayCardNumber.split('-');
      if (this.dispCardNumber !== undefined && this.dispCardNumber !== null) {
        this.dispCardNumber.cardNumber = this.dispCardNumber[this.FOUR];
      }
      // To get the Total amount
      if (this.invoicePaymentList !== null) {
        const paymentAmount = this.invoicePaymentList.map(
          (invoicePay) => invoicePay.paymentAmount.substring(1)
        );
        this.totalInvoiceAmount = paymentAmount.reduce(this.sumOfAmount, 0);
      }
    }
    this.csAgentAuthSubscription = this.csAgentAuthService.isCustomerEmulated().subscribe((res) => {
      this.isAsmEnabled = res ? res : false;
      if (this.isAsmEnabled) {
        this.asmEmailDetails = this.sharedService.getLocalStorage('asmEmail');
        this.personName =
          this.sharedService.getLocalStorage('creditPersonName');
        if (List !== null) {
          this.header = 'creditDetails';
          this.creditInvoice = '';
          this.creditPaymentList = List;
        } else {
          this.header = 'paymentDetails';
          this.creditInvoice = 'invoiceTable';
          this.invoicePaymentList =
            this.sharedService.getLocalStorage('invoicesData');
          this.selectedCardDetails =
            this.sharedService.getLocalStorage('selectedCard');
          this.dispCardNumber =
            this.selectedCardDetails?.displayCardNumber.split('-');
          if (
            this.dispCardNumber !== undefined &&
            this.dispCardNumber !== null
          ) {
            this.dispCardNumber.cardNumber = this.dispCardNumber[this.FOUR];
          }
          // To get the Total amount
          if (this.invoicePaymentList !== null) {
            const paymentAmount = this.invoicePaymentList.map((invoicePay) =>
              invoicePay.paymentAmount.substring(1)
            );
            this.totalInvoiceAmount = paymentAmount.reduce(this.sumOfAmount, 0);
          }
        }
      }
    });
  }
  getInvoiceTotal() {
    return 1;
  }
  sortColumn(event, fieldName, dataList, type): any {
    CommonUtils.onSortClick(event, fieldName, dataList, type); // Column Sorting
  }
  back(): void {
    this.router.navigate(['/openInvoicePayment']);
  }
  submitPayment(): void {
    let mailId;
    this.invoiceSubmitFlag = true;
    if (this.isAsmEnabled) {
      if (this.asmEmailDetails.selectedEmail === 'Add Other E-mail Address') {
        mailId = this.asmEmailDetails.newEmail;
      } else {
        mailId = this.asmEmailDetails.selectedEmail;
      }
    } else {
      mailId = this.userData.email;
    }
    const creditCardPaymentObj = {
      name: this.personName,
      email: mailId,
      message: '',
      openItems_request: {
        requestData: [],
      },
      creditCard_request: {
        cardType: this.selectedCardDetails?.cardType,
        ccnum: this.selectedCardDetails?.cardNumber,
        card_holder_name: this.selectedCardDetails?.creditCardName,
        cardExpDate: this.selectedCardDetails?.validTo,
        customer: this.b2bUnitID,
      },
    };

    this.invoicePaymentList.forEach((res) => {
      creditCardPaymentObj.openItems_request.requestData.push({
        fis_year: res.fiscYear,
        invoice_amount: res.amount,
        payment_amount: res.paymentAmount.substring(1),
        duedate: res.dueDate,
        docDate: res.docDate,
        invoice_number: res.docNo,
        postingDate: res.pstngDate,
        item_num: res.itemNum,
        docType: res.docType,
        invoice_dochdrtxt: res.dochdrtxt,
        invoice_refDoc_no: res.refDocNo,
        invoice_refhdr: res.refhdr,
        invoice_alloc_no: res.allocNmbr
      });
    });
    this.creditCardSubscription = this.makeAPayment.makeCreditCardPayment(this.userData?.userName, creditCardPaymentObj).subscribe(
      (res) => {
        this.sharedService.setSessionStorage('paymentConfirmation', res);
        if (this.isAsmEnabled) {
          this.sharedService.setLocalStorage('paymentConfirmation', res);
        }
        this.router.navigate([this.invoiceConfirmation]);
      },
      (err) => {
        this.invoiceSubmitFlag = false;
        this.cdr.detectChanges();
      }
    );
  }
  applyCredits(): void {
    this.creditSubmitFlag = true;
    this.formatValue = this.formatCreditJson();
    this.saveCreditSubscription = this.makeAPayment.saveCreditDetails(this.userData?.userName, this.formatValue).subscribe(
      (res) => {
        this.sharedService.setSessionStorage('creditConfirmation', res);
        if (this.isAsmEnabled) {
          this.sharedService.setLocalStorage('creditConfirmation', res);
        }
        this.router.navigate([this.invoiceConfirmation]);
      },
      (err) => {
        this.creditSubmitFlag = false;
        this.cdr.detectChanges();
      }
    );
  }

  // Addition of floating values
  sumOfAmount(sum, num): any {
    const data = num.replace(/,/g, '');
    return Number(sum) + parseFloat(data);
  }

  formatCreditJson(): any {
    const array = [];

    this.creditPaymentList.forEach((element) => {
      const obj = {
        creditnumber: element.docNo,
        creditAmount: element.amount,
        credit_fisc_year: element.fiscYear,
        credit_item_no: element.itemNum,
        invoicenumber: element.invoicenumber,
        invoiceAmount: element.invoiceamount,
        invoice_fisc_year: element.invoice_fisc_year,
        invoice_item_no: element.invoice_item_no,
        invoice_due_date: element.invoice_due_date,
        customer: element.customer,
        credit_docType: element.docType,
        credit_dochdrtxt: element.dochdrtxt,
        credit_due_date: element.dueDate,
        credit_refDoc_no: element.refDocNo,
        credit_refhdr: element.refhdr,
        credAlloc_nmbr: element.allocNmbr,
        invoice_docType: element.invoice_docType,
        invoice_dochdrtxt: element.invoice_dochdrtxt,
        invoice_refDoc_no: element.invoice_refDocNo,
        invoice_refhdr: element.invoice_refhdr,
        invAlloc_nmbr: element.invoice_allocNo
      };
      array.push(obj);
    });
    let mailId;
    if (this.isAsmEnabled) {
      if (this.asmEmailDetails.selectedEmail === 'Add Other E-mail Address') {
        mailId = this.asmEmailDetails.newEmail;
      } else {
        mailId = this.asmEmailDetails.selectedEmail;
      }
    } else {
      mailId = this.userData.email;
    }
    return {
      creditItems: array,
      name: this.personName,
      email: mailId,
    };
  }

  invoicePdf(invoiceNo): void {
    this.makeAPayment.downloadPdf(invoiceNo);
  }

  creditPdf(creditNo): void {
    this.makeAPayment.downloadPdf(creditNo);
  }

  ngOnDestroy() {
    this.userSubscription?.unsubscribe();
    this.accountIdSubscription?.unsubscribe();
    this.csAgentAuthSubscription?.unsubscribe();
    this.creditCardSubscription?.unsubscribe();
    this.saveCreditSubscription?.unsubscribe();
  }
}
