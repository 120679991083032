import { CommonModule, CurrencyPipe, DatePipe } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  BrowserModule,
  BrowserTransferStateModule,
} from '@angular/platform-browser';
import { NgSelectModule } from '@ng-select/ng-select';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { HttpErrorHandler } from '@spartacus/core';
import { ProductListComponentService } from '@spartacus/storefront';
import { LayoutConfigModule } from '../../src/features/layout/layout.module';
import { OrderHistoryModule } from '../app/order-history/order-history.module';
import { AccountOverviewModule } from './account-overview/account-overview.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CustomAddToCartModule } from './custom-add-to-cart/custom-add-to-cart.module';
import { CustomBannerModule } from './custom-banner/custom-banner.module';
import { CustomHeaderModule } from './custom-header/custom-header.module';
import { CustomInfluenzaModule } from './custom-influenza/custom-influenza.module';
import { CustomMakeAPaymentModule } from './custom-make-a-payment/custom-make-a-payment.module';
import { CustomPdpModule } from './custom-pdp/custom-pdp.module';
import { CustomProdListService } from './custom-prod-pg/custom-prod-list.service';
import { CustomProfileAndPreferencesModule } from './custom-profile-and-preferences/custom-profile-and-preferences.module';
import { CustomUserRegistrationModule } from './custom-user-registration/custom-user-registration.module';
import { FaqModule } from './faq/faq.module';
import { ForbiddenHandler } from './forbidden-handler.service';
import { ForgotUserPasswordModule } from './forgot-user-password/forgot-user-password.module';
import { HomeScreenModule } from './home-screen/home-screen/home-screen.module';
import { AuthInterceptorService } from './interceptor-service';
import { SearchBoxModule } from './search-box/search-box.module';
import { AddCardModule } from './shared/modules/add-card/add-card.module';
import { BackToTopModule } from './shared/modules/back-to-top/back-to-top.module';
import { CheckoutModule } from './shared/modules/checkout/checkout.module';
import { CustomBottomFooterLinksModule } from './shared/modules/custom-bottom-footer-links/custom-bottom-footer-links.module';
import { ExternalsiteAlertModule } from './shared/modules/externalsite-alert/externalsite-alert.module';
import { LocationStripModule } from './shared/modules/location-strip/location-strip.module';
import { SpartacusModule } from './spartacus/spartacus.module';
import { StatementsModule } from './statements/statements.module';
import { ToolsAndResourcesModule } from './tools-and-resources/tools-and-resources.module';
import { UserAccessManagementModule } from './user-access-management/user-access-management.module';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    HttpClientModule,
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    AppRoutingModule,
    StoreModule.forRoot({}),
    EffectsModule.forRoot([]),
    SpartacusModule,
    LayoutConfigModule,
    BrowserTransferStateModule,
    CustomMakeAPaymentModule,
    CustomPdpModule,
    CustomInfluenzaModule,
    CustomUserRegistrationModule,
    CustomAddToCartModule,
    NgSelectModule,
    FormsModule,
    HomeScreenModule,
    ForgotUserPasswordModule,
    CustomProfileAndPreferencesModule,
    AddCardModule,
    CheckoutModule,
    ExternalsiteAlertModule,
    SearchBoxModule,
    CustomHeaderModule,
    LocationStripModule,
    OrderHistoryModule,
    StatementsModule,
    SearchBoxModule,
    AccountOverviewModule,
    FaqModule,
    ToolsAndResourcesModule,
    UserAccessManagementModule,
    CustomBannerModule,
    BackToTopModule,
    CustomBottomFooterLinksModule,
  ],
  providers: [
    {
      provide: ProductListComponentService,
      useClass: CustomProdListService,
    },
    {
      provide: HttpErrorHandler,
      useExisting: ForbiddenHandler,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true,
    },

    DatePipe,
    CurrencyPipe,
  ],

  bootstrap: [AppComponent],
})
export class AppModule {}
