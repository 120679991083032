import { DatePipe } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  faFileExcel,
  faRectangleList,
  faTrashCan,
} from '@fortawesome/free-regular-svg-icons';
import { faChevronLeft, faPrint } from '@fortawesome/free-solid-svg-icons';
import {
  ModalDismissReasons,
  NgbModal,
  NgbPopover,
  NgbPopoverConfig,
} from '@ng-bootstrap/ng-bootstrap';
import {
  CmsService,
  GlobalMessageService,
  GlobalMessageType,
  OccEndpointsService,
  RoutingService,
} from '@spartacus/core';
import { UserAccountFacade } from '@spartacus/user/account/root';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { enGbLocale } from 'ngx-bootstrap/locale';
import { Subscription } from 'rxjs';
import { CustomProdPgService } from 'src/app/custom-prod-pg/custom-prod-pg.service';
import { DataLayerService } from 'src/app/shared/services/data-layer.service';
import { ProductUtils } from 'src/app/shared/utils/productUtils';
import * as _ from 'underscore';
import { CustomAddToCartService } from '../../custom-add-to-cart.service';
import { ProductDetailsComponent } from '../product-details/product-details.component';

@Component({
  selector: 'app-standard-cart',
  templateUrl: './standard-cart.component.html',
  styleUrls: ['./standard-cart.component.scss'],
})
export class StandardCartComponent implements OnInit, OnDestroy {
  deliveryPickup: string = 'delivery';
  isSticky: boolean = false;
  public productInfo: {
    available: boolean;
    availabilityText: string;
    batchInfo: {
      batchNumber: string;
      expiryDate: string;
      quantity: number;
    };
    images: any;
    code: string;
    description: string;
    discontinued: boolean;
    sapAllocation: boolean;
    displayProduct: boolean;
    flu: boolean;
    nameHtml: any;
    summary: any;
    productUpdate: string;
    productDocuments: {
      productDocument: [
        {
          url: string;
        }
      ];
    };
    name: string;
    orderable: boolean;
    quantityPerUnit: number;
    productUpdates: string;
    productDocument: any;
    volumePrices: [
      {
        currencyIso: string;
        formattedValue: string;
        maxQuantity: string;
        minQuantity: string;
        priceType: string;
        value: string;
      }
    ];
    url: string;
    stock: {
      isValueRounded: boolean;
    };
    isValueRounded: boolean;
    yellowFever: boolean;
    volumePricesFlag: boolean;
  } = null;
  standardCartDetails: any;
  productEntries: any = [];
  public images: any;
  updateTotal: boolean;
  updateEntriesData: any = [];
  subTotal: number;
  baseUrl: string;
  minDeliveryDate = new Date();
  maxDeliveryDate = new Date();
  minPickupDate = new Date();
  maxPickupDate = new Date();
  currentTime = new Date().getHours();
  pickupTimeFormat: any = [];
  timeFormat: any;
  selectedTime: any;
  deliveryDate: Date;
  deliveryCrossIcon: boolean;
  pickupDate: Date;
  deliveryDateFormat: string;
  pickupDateFormat: string;
  pickupCrossIcon: boolean;
  fallbackImage = '../../assets/i18n-assets/sanofishoppe_missing_product.png';
  closeResult = '';
  usergetDataObj: any;
  getuserData: any;
  searchedClientList: any;
  selectedData: any;
  soldToData: any;
  listSelectedData: any;
  userAccountgetData: any;
  saveCartName: string;
  popoverConfig: NgbPopoverConfig;
  cartId: any;
  errorMsg: string;
  deleteSuccess: string;
  addSuccess = false;
  cartItems: any;
  changeLocation = false;
  templatePage: any;
  prodAddedSub: Subscription;
  cartItemsSub: Subscription;
  removeEntriesSub: Subscription;
  deleteCartSub: Subscription;
  cartDetailsSub: Subscription;
  checkoutDetailsSub: Subscription;
  checkoutFailedSub: Subscription;
  userAccountDetailsSub: Subscription;
  changedLocSub: Subscription;
  saveCartInvalidSub: Subscription;
  saveCartSuccessSub: Subscription;
  plpMiniCartSub: Subscription;
  userDataSub: Subscription;
  saveRemovedSub: Subscription;
  @ViewChild('p2') public savePopover: NgbPopover;
  orderBlock = false;
  showDangerAlert = false;
  checkoutClicked = false;
  yfEntry = false;
  quickOrderRemovedEntry: boolean = false;
  // icons
  faPrint = faPrint;
  faFileExcel = faFileExcel;
  faTrashCan = faTrashCan;
  faRectangleList = faRectangleList;
  faChevronLeft = faChevronLeft;
  cartDetailsRan: boolean = false;

  constructor(
    private readonly cdr: ChangeDetectorRef,
    private readonly addToCart: CustomAddToCartService,
    readonly user: UserAccountFacade,
    private readonly modalService: NgbModal,
    private localeService: BsLocaleService,
    public datepipe: DatePipe,
    private readonly config: NgbPopoverConfig,
    private readonly route: RoutingService,
    private readonly occEndPoint: OccEndpointsService,
    private readonly plpService: CustomProdPgService,
    private globalMessage: GlobalMessageService,
    private readonly pageDate: CmsService,
    private readonly dataLayerService: DataLayerService
  ) {
    this.images = {};
    this.updateTotal = false;
    enGbLocale.weekdaysShort = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];
    enGbLocale.week.dow = 0;
    defineLocale('en-gb', enGbLocale);
    this.localeService.use('en-gb');
    this.pickupTimeFormat = [
      '09:00 am',
      '10:00 am',
      '11:00 am',
      '12:00 pm',
      '01:00 pm',
      '02:00 pm',
      '03:00 pm',
      '04:00 pm',
      '05:00 pm',
    ];
    this.selectedTime = null;
    config.autoClose = 'outside';
    this.deleteSuccess = '';
    this.addSuccess = false;
  }
  @HostListener('window:scroll', ['$event'])
  checkScroll() {
    this.isSticky = window.pageYOffset >= 400;
  }

  ngOnInit(): void {
    this.globalMessage.remove(GlobalMessageType.MSG_TYPE_ERROR);
    this.plpMiniCartSub = this.plpService
      .getUpdateMiniCart()
      .subscribe((res) => {
        if (res) {
          this.addToCart.removedEntries(res, this.userAccountgetData?.userName);
          this.addToCart.removeEntriesMapping();
        }
      });
    this.plpService.stdCartMsg.next(false);
    this.baseUrl = this.occEndPoint.getBaseUrl({
      baseUrl: true,
      prefix: false,
      baseSite: false,
    });
    this.pageDate.getCurrentPage().subscribe((res) => {
      if (res) {
        this.templatePage = res?.template;
      }
    });
    this.userDataSub = this.user.get().subscribe((res) => {
      if (res && res != null) {
        this.userAccountgetData = res;
        this.getUserAccountData(this.userAccountgetData.userName);
        // this.addToCart.allCartItems$.subscribe((res1) => {
        //   if (res1) {
        //     this.cartItems = res1?.carts;
        //     this.cartItems = this.cartItems[this.cartItems?.length - 1]
        //     this.cartId = this.cartItems?.code;
        //     if (this.cartItems === undefined) {
        this.productEntries = [];
        //}
        this.getCartDetails();
        this.cdr.detectChanges();
        // }
        //});
      }
    });
    this.prodAddedSub = this.plpService.stdCartMsg$.subscribe((res) => {
      if (res) {
        this.addSuccess = true;
        this.deleteSuccess = '';
        this.plpService.stdCartMsg.next(false);
      }
    });
    this.setDeliveryDate();
    this.setPickupDate();
  }
  getCartDetails() {
    //this.addToCart.getCarts(this.cartId, this.userAccountgetData?.userName);
    this.cartItemsSub = this.addToCart.cartItems$.subscribe((cartData: any) => {
      if (cartData) this.cartDataMapping(cartData);
    });
    if (this.cartId === undefined) {
      this.cartId = this.addToCart.cartId;
    }
    if (this.cartId && this.templatePage === 'CartPageTemplate') {
      this.addToCart.removedEntries(
        this.cartId,
        this.userAccountgetData?.userName
      );
      this.addToCart.removeEntriesMapping();
    }
  }

  cartDataMapping(data): void {
    let stdCartDetails = [];
    this.productEntries = [];

    this.cartId = data?.code;
    stdCartDetails = data?.childCarts;
    if (stdCartDetails) {
      stdCartDetails = stdCartDetails.filter(
        (item) => item.cartType === 'STANDARD'
      );

      if (!this.cartDetailsRan) {
        //if that thing hasn't happened yet and the mouse is pressed
        this.cartDetailsRan = true;
        this.dataLayerService.viewCartDataLayer(stdCartDetails);
      }

      this.standardCartDetails = Object.assign({}, ...stdCartDetails);
      for (let cartDetails of stdCartDetails) {
        cartDetails?.entries == null;
        for (let entries of cartDetails.entries) {
          let removedEntry = undefined;
          if (data?.removedEntries?.cartModifications !== undefined) {
            for (let cartModification of data?.removedEntries
              ?.cartModifications) {
              if (
                entries?.product !== undefined &&
                cartModification?.entry?.product !== undefined
              ) {
                if (
                  entries?.product?.code ===
                  cartModification?.entry?.product?.code
                ) {
                  removedEntry = true;
                }
              }
            }
          } else {
              this.saveRemovedSub = this.addToCart.saveRemovedItems$.subscribe((data: any) => {
                  if (data?.removedEntries?.cartModifications !== undefined) {
                      for (let cartModification of data?.removedEntries?.cartModifications) {
                          this.globalMessage.add(cartModification?.statusMessage, GlobalMessageType.MSG_TYPE_ERROR, 5000);
                      }
                  }
              });
          }
          if (removedEntry === true) {
            this.quickOrderRemovedEntry = true;
            continue;
          } else {
            this.quickOrderRemovedEntry = false;
          }
          let imagesFormat = _.clone(
            _.findWhere(entries.product?.images, { format: 'thumbnail' })
          );
          if (imagesFormat) imagesFormat.url = this.baseUrl + imagesFormat?.url;
          this.images = { PRIMARY: imagesFormat };
          entries = { ...entries, ...this.images };
          this.productEntries.push(entries);
        }
          this.addToCart.saveRemovedItems.next(false);
      }
      if (this.standardCartDetails?.shipToParty) {
        this.selectedData =
          this.standardCartDetails?.shipToParty?.shippingAddresses.filter(
            (item) => item.unitID === this.standardCartDetails.shipToParty.uid
          );
        if (!!this.selectedData?.length) {
          this.selectedData = _.first(this.selectedData);
        } else {
          this.selectedData = _.first(
            this.standardCartDetails?.shipToParty?.shippingAddresses
          );
        }
      } else {
        if (this.getuserData && this.getuserData?.length > 0) {
          this.selectedData = _.first(
            this.getuserData.filter(
              (item) => item.unitID === this.userAccountgetData?.uid
            )
          );
        }
      }

      //disables the display to not show soldto
      this.soldToData = null;
      // this implementation has been hidden until data received from backend is fixed
      // this.soldToData = !!this.standardCartDetails?.soldtoAddress ? this.standardCartDetails.soldtoAddress : this.usergetDataObj?.soldToAddress;
      
      
      this.saveCartName = this.selectedData?.companyName?.slice(0, 30);

      this.cdr.detectChanges();
    }
  }
  performQuantityComputation(
    operator,
    idData,
    quantityOfUnits,
    entryNumber
  ): void {
    ProductUtils.alterQuantity(operator, idData, quantityOfUnits);
    this.updateTotal = true;
    const inputElement = document.getElementById(
      `prodQuantity${idData}`
    ) as HTMLInputElement;
    const params = { entryNumber: entryNumber, quantity: inputElement.value };
    if (
      this.updateEntriesData.some(
        ({ entryNumber }) => entryNumber === params.entryNumber
      )
    ) {
      this.updateEntriesData[
        this.updateEntriesData.findIndex(
          (el) => el.entryNumber === params.entryNumber
        )
      ] = params;
    } else {
      this.updateEntriesData = [...this.updateEntriesData, params];
    }
  }
  deleteCart(entryNumber, prodName): void {
    this.addToCart.deleteCartItem(
      entryNumber,
      this.standardCartDetails.code,
      this.cartId
    );
    this.deleteCartSub = this.addToCart.deleteItems$.subscribe((res) => {
      if (res) {
        this.addToCart.deleteItems.next(false);
        this.addToCart.removedEntries(
          this.cartId,
          this.userAccountgetData?.userName
        );
        this.addToCart.removeEntriesMapping();
        this.deleteSuccess = prodName;
        this.addSuccess = false;
      }
    });
  }
  closeAlert() {
    this.deleteSuccess = '';
    this.addSuccess = false;
  }
  closeDangerAlert() {
    this.showDangerAlert = false;
  }
  clearChildCart(): void {
    this.addToCart.clearCart(this.standardCartDetails.code);
  }
  openPopover(popover) {
    popover.open();
  }
  toggle(popover) {
    if (popover.isOpen()) {
      popover.close();
    } else {
      popover.open();
    }
    this.errorMsg = '';
    this.saveCartName = this.selectedData?.companyName?.slice(0, 30);
  }
  updateCart(): void {
    this.addToCart.updateCartDetails(
      this.standardCartDetails.code,
      this.updateEntriesData,
      this.cartId
    );
    this.cartDetailsSub = this.addToCart.cartDetails$.subscribe((res: any) => {
      if (res) {
        this.addToCart.removedEntries(this.cartId, this.addToCart.userId);
        this.addToCart.removeEntriesMapping();
        this.updateTotal = false;
        let stdCartDetails = [];
        stdCartDetails = res?.childCarts;
        stdCartDetails = stdCartDetails.filter(
          (item) => item.cartType === 'STANDARD'
        );
        this.standardCartDetails = Object.assign({}, ...stdCartDetails);
        this.updateEntriesData = [];
      }
    });
    this.cdr.detectChanges();
  }
  productDetails(productDetails): void {
    const modalRef = this.modalService.open(ProductDetailsComponent, {
      windowClass: 'product-details-modal',
    });
    modalRef.componentInstance.productDetails = productDetails;
    modalRef.componentInstance.standardCartCode = this.standardCartDetails.code;
    modalRef.componentInstance.unitSection = 'Update';
  }

  onDeliveryDateChange(value: Date): void {
    if (value) {
      this.deliveryCrossIcon = true;
      this.deliveryDateFormat = this.datepipe.transform(value, 'yyyy-MM-dd');
    } else {
      this.deliveryCrossIcon = false;
    }
  }

  clearDate(dpDate): void {
    if (dpDate === 'Delivery') {
      this.deliveryDate = null;
      this.deliveryCrossIcon = false;
    } else {
      this.pickupDate = null;
      this.pickupCrossIcon = false;
      this.selectedTime = null;
    }
  }
  proceedToCheckout(): void {
    if (this.deliveryDate) {
      this.onDeliveryDateChange(this.deliveryDate);
      const params = {
        customerId: this.usergetDataObj.uid,
        date: this.deliveryDateFormat,
        deliveryMode: 'standard',
        pickupTime: '',
        shipToUnitId: this.selectedData?.unitID,
        standard: true,
      };
      this.addToCart.checkoutCart(params, this.cartId);
      this.checkoutClicked = true;
      this.checkoutDetailsSub = this.addToCart.checkoutDetails$.subscribe(
        (res) => {
          if (res) {
            this.checkoutClicked = false;
            this.route.goByUrl('/checkout');
          }
        }
      );
      this.checkoutFailedSub = this.addToCart.checkoutFailed$.subscribe(
        (res) => {
          if (res) {
            this.checkoutClicked = false;
          }
        }
      );
    }
  }

  open(content): void {
    this.searchedClientList = this.getuserData;
    this.modalService
      .open(content, { ariaLabelledBy: 'modal-basic-title' })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  getUserAccountData(userId): void {
    this.userAccountDetailsSub = this.addToCart.userAccountDetails$.subscribe(
      (userDataget) => {
        if (userDataget) {
          this.usergetDataObj = userDataget.res;
          this.getuserData = userDataget.res.shippingAddresses;
          this.searchedClientList = userDataget.res.shippingAddresses;
          this.orderBlock = userDataget?.res?.orderBlockFlag ? true : false;
          if (this.orderBlock) {
            this.showDangerAlert = true;
          }
          this.changeLocation = this.getuserData?.length <= 1 ? false : true;
          this.cdr.detectChanges();
        }
      }
    );
  }

  onOptionsSelected(data) {
    this.listSelectedData = data;
  }

  onSubmit(): void {
    if (this.listSelectedData != undefined) {
      this.selectedData = this.listSelectedData;
      this.addToCart.saveLocation(
        this.selectedData?.unitID,
        this.cartId,
        this.userAccountgetData?.userName
      );
      this.changedLocSub = this.addToCart.changedLocation$.subscribe((res) => {
        if (res) {
          this.addToCart.removedEntries(
            this.cartId,
            this.userAccountgetData?.userName
          );
          this.addToCart.removeEntriesMapping();
        }
      });
    }
  }
  SearchClientName(data: any) {
    if (data.length > 0) {
      this.searchedClientList = this.getuserData.filter(
        (x) =>
          (x.companyName != undefined &&
            x.companyName.toLowerCase().indexOf(data.toLowerCase()) >= 0) ||
          (x.unitID != undefined &&
            x.unitID.toLowerCase().indexOf(data.toLowerCase()) >= 0) ||
          (x.line1 != undefined &&
            x.line1.toLowerCase().indexOf(data.toLowerCase()) >= 0) ||
          (x.line2 != undefined &&
            x.line2.toLowerCase().indexOf(data.toLowerCase()) >= 0) ||
          (x.town != undefined &&
            x.town.toLowerCase().indexOf(data.toLowerCase()) >= 0) ||
          (x.postalCode != undefined &&
            x.postalCode.toLowerCase().indexOf(data.toLowerCase()) >= 0)
      );
    } else {
      this.searchedClientList = this.getuserData;
    }
  }
  saveOrder(): void {
    var reg = /^[^`!@#~$%\^&*()_+\-={};':"\\|[\]\\.,<>/\/?~]*$/;
    if (this.saveCartName && RegExp(reg).test(this.saveCartName)) {
      this.addToCart.saveCartDetails(this.saveCartName, this.cartId);
      this.saveCartInvalidSub = this.addToCart.invalidCartName$.subscribe(
        (res) => {
          if (res) {
            for (let error of res?.error?.errors) {
              this.errorMsg = error?.message;
            }
          }
        }
      );
      this.saveCartSuccessSub = this.addToCart.saveCartSuccess$.subscribe(
        (res) => {
          if (res) {
            if (this.savePopover) {
              this.savePopover.close();
            }
            this.errorMsg = '';
            this.saveCartName = this.selectedData?.companyName?.slice(0, 30);
          }
        }
      );
    } else {
      if (this.saveCartName) {
        this.errorMsg = 'Save cart name is not valid.';
      } else {
        this.errorMsg = 'Cart name cannot be blank';
      }
    }
  }
  exportXls() {
    let cartProducts = [];
    for (let entries of this.productEntries) {
      cartProducts.push({
        'Product No.': entries?.product?.code,
        Quantity: entries?.quantity,
        Location:
          '(' +
          this.selectedData?.unitID +
          ')' +
          this.selectedData?.line1 +
          ', ' +
          (this.selectedData?.line2 ? this.selectedData?.line2 + ', ' : '') +
          this.selectedData?.town +
          ', ' +
          this.selectedData?.postalCode,
      });
    }
    this.addToCart.downloadXls(cartProducts, 'Cart');
  }
  omit_special_char(event) {
    this.errorMsg = '';
    var reg = /^[^`!@#~$%\^&*()_+\-={};':"\\|[\]\\.,<>/\/?~]*$/;
    return new RegExp(reg).test(event.key);
  }
  setDeliveryDate() {
    this.minDeliveryDate.setDate(this.minDeliveryDate.getDate());
    if (
      this.minDeliveryDate.getDay() === 0 ||
      this.minDeliveryDate.getDay() === 6
    ) {
      for (let i = 1; i <= 3; i++) {
        this.minDeliveryDate.setDate(this.minDeliveryDate.getDate() + 1);
        if (
          this.minDeliveryDate.getDay() !== 0 &&
          this.minDeliveryDate.getDay() !== 6
        )
          break;
      }
    }
    this.maxDeliveryDate.setDate(this.minDeliveryDate.getDate() + 6);

    this.deliveryDate = this.minDeliveryDate;
  }
  setPickupDate() {
    if (this.currentTime < 12) {
      // Date Set
      this.minPickupDate.setDate(this.minPickupDate.getDate());
      if (
        this.minPickupDate.getDay() === 0 ||
        this.minPickupDate.getDay() === 6
      ) {
        for (let i = 1; i <= 3; i++) {
          this.minPickupDate.setDate(this.minPickupDate.getDate() + 1);
          if (
            this.minPickupDate.getDay() !== 0 &&
            this.minPickupDate.getDay() !== 6
          )
            break;
        }
      }
      this.maxPickupDate.setDate(this.minPickupDate.getDate() + 6);
    } else {
      this.minPickupDate.setDate(this.minPickupDate.getDate() + 1);
      if (
        this.minPickupDate.getDay() === 0 ||
        this.minPickupDate.getDay() === 6
      ) {
        for (let i = 1; i <= 3; i++) {
          this.minPickupDate.setDate(this.minPickupDate.getDate() + 1);
          if (
            this.minPickupDate.getDay() !== 0 &&
            this.minPickupDate.getDay() !== 6
          )
            break;
        }
      }
      this.maxPickupDate.setDate(this.minPickupDate.getDate() + 6);
    }
    this.pickupDate = this.minPickupDate;
  }
  setPickupTime() {
    if (this.currentTime < 10) {
      this.timeFormat = this.pickupTimeFormat.slice(3);
    } else if (this.currentTime === 10) {
      this.timeFormat = this.pickupTimeFormat.slice(4);
    } else if (this.currentTime === 11) {
      this.timeFormat = this.pickupTimeFormat.slice(6);
    } else if (this.currentTime > 11 && this.currentTime < 17) {
      this.timeFormat = this.pickupTimeFormat.slice(0);
    } else if (this.currentTime > 16) {
      this.timeFormat = this.pickupTimeFormat.slice(3);
    }
    this.selectedTime = _.first(this.timeFormat);
  }
  ngOnDestroy() {
    this.prodAddedSub?.unsubscribe();
    this.cartItemsSub?.unsubscribe();
    this.removeEntriesSub?.unsubscribe();
    this.deleteCartSub?.unsubscribe();
    this.cartDetailsSub?.unsubscribe();
    this.checkoutDetailsSub?.unsubscribe();
    this.checkoutFailedSub?.unsubscribe();
    this.userAccountDetailsSub?.unsubscribe();
    this.changedLocSub?.unsubscribe();
    this.saveCartInvalidSub?.unsubscribe();
    this.saveCartSuccessSub?.unsubscribe();
    this.plpMiniCartSub?.unsubscribe();
    this.userDataSub?.unsubscribe();
    this.globalMessage.remove(GlobalMessageType.MSG_TYPE_ERROR);
    this.saveRemovedSub?.unsubscribe();
  }
}
