import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BaseSiteService } from '@spartacus/core';
import { UserAccountFacade } from '@spartacus/user/account/root';
import { take } from 'rxjs/operators';
import { LoactionStripService } from '../../shared/modules/location-strip/loaction-strip.service';
import { SharedService } from '../../shared/services/shared.service';

@Component({
  selector: 'app-custom-header-change-account',
  templateUrl: './custom-header-change-account.component.html',
})
export class CustomHeaderChangeAccountComponent implements OnInit, OnDestroy {
  usergetDataObj: any;
  userAccountId: string;
  userAccountgetData: any;
  showLocStrip = false;
  showChangeOption: boolean;
  baseSiteId: string;
  selectedLocsData: any;
  defaultLocationTitle: string;
  locationTitle: string;
  soldToLocations: Array<any>;
  soldToSearchedLocations: Array<any>;
  userAccountSubscriber: any;
  userAccountDataSubscriber: any;
  userAccountAddressDetailsSubscriber: any;
  selectedAccountSub: any;
  @ViewChild('content') modalContent;

  constructor(
    private readonly locService: LoactionStripService,
    private readonly user: UserAccountFacade,
    private readonly ref: ChangeDetectorRef,
    private readonly baseSiteService: BaseSiteService,
    protected readonly modal: NgbModal,
    private readonly sharedService: SharedService
  ) {}

  ngOnInit(): void {
    this.userAccountSubscriber = this.user.get().subscribe((res) => {
      if (res) {
        this.userAccountgetData = res;
        this.showLocStrip = true;
      } else {
        this.showLocStrip = false;
      }
      this.ref.detectChanges();
    });

    this.userAccountAddressDetailsSubscriber =
      this.locService.userAccountAddressDetails.subscribe((response) => {
        if (response?.res) {
          this.soldToLocations = response.res?.addresses as Array<{}>;
          this.soldToSearchedLocations = this.soldToLocations;
          if (
            !!this.soldToLocations?.length &&
            this.soldToLocations?.length > 1
          ) {
            this.showChangeOption = true;
            if (!this.sharedService.getSessionStorage('isShowFlag')) {
              this.openModal(this.modalContent);
            }
          } else {
            this.showChangeOption = false;
          }
        }
        this.ref.detectChanges();
      });

    this.userAccountDataSubscriber = this.locService.userAccountData.subscribe(
      (userData) => {
        if (userData.res) {
          this.sharedService.setSessionStorage(
            'userAccountId',
            userData?.res?.defaultB2BUnit?.uid
          );
          this.userAccountId = userData?.res?.defaultB2BUnit?.uid;
        }
      }
    );

    this.baseSiteService
      .getActive()
      .pipe(take(1))
      .subscribe((res) => (this.baseSiteId = res));
  }

  openModal(content) {
    this.modal.open(content, {
      centered: true,
      backdrop: 'static',
      windowClass: `locStripModal ${this.baseSiteId}`,
    });
    this.sharedService.setSessionStorage('isShowFlag', true);
  }

  SearchClientName(data: any) {
    if (data.length > 0) {
      this.soldToLocations = this.soldToSearchedLocations.filter(
        (x) =>
          (x.companyName != undefined &&
            x.companyName.toLowerCase().indexOf(data.toLowerCase()) >= 0) ||
          (x.unitID != undefined &&
            x.unitID.toLowerCase().indexOf(data.toLowerCase()) >= 0)
      );
    } else {
      this.soldToLocations = this.soldToSearchedLocations;
    }
  }

  onOptionsSelected(data) {
    this.selectedLocsData = data;
  }

  changeAccount() {
    if (this.selectedLocsData != undefined) {
      this.locService.saveSelectedAccount(
        this.userAccountgetData?.userName,
        this.selectedLocsData?.unitID
      );
    }
    this.modal.dismissAll();
  }

  ngOnDestroy() {
    if (this.userAccountSubscriber) {
      this.userAccountSubscriber.unsubscribe();
    }
    if (this.userAccountDataSubscriber) {
      this.userAccountDataSubscriber.unsubscribe();
    }
    if (this.userAccountAddressDetailsSubscriber) {
      this.userAccountAddressDetailsSubscriber.unsubscribe();
    }
  }
}
