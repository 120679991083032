import {Injectable} from '@angular/core';
import {BehaviorSubject, Subject} from 'rxjs';
import * as config from '../../config/app.config';
import {BaseService} from '../../services/base.service';
import {GlobalMessageService, GlobalMessageType} from "@spartacus/core";
import {ErrorChangeAccountMsgEng} from "../../../custom-header/header.constants";


@Injectable({
    providedIn: 'root'
})
export class LoactionStripService {
    userAccountType: any;
    userAccountData = new BehaviorSubject<any>(false);
    userAccountData$ = this.userAccountData.asObservable();
    saveAccount: any;
    errorMessageText: string = ErrorChangeAccountMsgEng;
    public userAccountDetails = new BehaviorSubject<any>(false);
    userAccountDetails$ = this.userAccountDetails.asObservable();
    public userAccountAddressDetails = new Subject<any>();
    userAccountAddressDetails$ = this.userAccountAddressDetails.asObservable();
    public endSession = new BehaviorSubject<any>(false);
    endSession$ = this.endSession.asObservable();
    emitSelectedLocs = new BehaviorSubject<any>(false);
    emitSelectedLocs$ = this.emitSelectedLocs.asObservable();
    openModal = new BehaviorSubject<boolean>(false);
    openModal$ = this.openModal.asObservable();

    soldToLocations: Array<any>;

    constructor(private readonly baseService: BaseService,
                private readonly globalMessage: GlobalMessageService
    ) {
        this.saveAccount = new Subject();

    }

    public getUserAccountDataFromDb(userId): void {
        this.baseService
            .get(config.USER_ACCOUNT + userId + '/b2bUnits', { fields: 'FULL' })
            .subscribe((res) => {
                if (res) {
                    this.userAccountType = res.type;
                    this.userAccountData.next({res})
                }
            });
    }


    public getUserAccountAddressesDataFromDb(userId): void {
        this.baseService
            .get(config.USER_ACCOUNT + userId + '/accounts', {fields: 'FULL'})
            .subscribe((res) => {
                this.userAccountAddressDetails.next({res});
            });
    }

    public saveSelectedAccount(userId, b2bUnitId): void {
        this.baseService
            .put(config.USER_ACCOUNT + userId + '/selectedUnit/' + b2bUnitId, {fields: 'FULL'})
            .subscribe((res) => {
                    this.saveAccount.next(true);
                    window.location.href = "my-account/account-overview";
                },
                (error) => {
                    if (!!error) {
                        window.scroll(0, 0);
                        this.globalMessage.add(
                            this.errorMessageText,
                            GlobalMessageType.MSG_TYPE_ERROR
                        );
                    }
                });
    }

    public disableLocStrip(endSessionFlag): void {
        this.endSession.next(endSessionFlag);
    }

}
