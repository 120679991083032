import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  FacetService,
  ProductListComponentService,
} from '@spartacus/storefront';
import { UserAccountFacade } from '@spartacus/user/account/root';
import { Subscription } from 'rxjs';
import { FaqService } from 'src/app/faq/faq.service';
import { CustomSearchService } from 'src/app/search-box/search-box/custom-search.service';
import { DataLayerService } from 'src/app/shared/services/data-layer.service';
import { LoactionStripService } from '../../shared/modules/location-strip/loaction-strip.service';
import { CustomProdPgService } from '../custom-prod-pg.service';

@Component({
  selector: 'app-custom-product-page',
  templateUrl: './custom-product-page.component.html',
})
export class CustomProductPageComponent implements OnInit, OnDestroy {
  public productList$ = this.productListService.model$;
  pagination: any;
  currentPage = 1;
  facets$: any;
  pageRange: any;
  productLotSize: number;
  pagesize: number;
  userDetails: any;
  selectedData: any;
  flagBivvCatalog: any;
  listSort: any;
  prodListSub: Subscription;
  facetListSub: Subscription;
  usedDataSub: Subscription;
  currentTab: string = 'product';
  searchInput: any;
  // faq = [];
  helpVal: any;
  filteredItems = [];
  // helpValSub: Subscription;
  constructor(
    private readonly productListService: ProductListComponentService,
    private readonly locService: LoactionStripService,
    private readonly user: UserAccountFacade,
    private readonly plpService: CustomProdPgService,
    private readonly facet: FacetService,
    private readonly dataLayerService: DataLayerService,
    private readonly faqService: FaqService,
    private readonly cd: ChangeDetectorRef,
    private readonly customSearchService: CustomSearchService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.locService.userAccountData.subscribe((result) => {
      if (result) {
        this.flagBivvCatalog = result.res?.defaultB2BUnit?.isCurrentCatalogBivv;
      }
    });
    this.prodListSub = this.productList$.subscribe((res) => {
      this.pagesize = res.pagination.pageSize;
      this.productLotSize = res.products.length;
      this.pagination = res.pagination;
      this.listSort = res.sorts;
      if (this.flagBivvCatalog) {
        this.selectedData = res?.sorts?.find(
          (item) => item.code === 'topRated'
        ).name;
      } else {
        this.selectedData = res?.sorts?.find(
          (item) => item.code === 'name-asc'
        )?.name;
        this.listSort = res.sorts.filter((item) => item.code != 'topRated');
      }

      this.performPagination(this.pagination.currentPage);
      this.dataLayerService.viewListItemsDataLayer(res);
    });

    this.facetListSub = this.facet.facetList$.subscribe((res) => {
      this.facets$ = res.activeFacets;
    });
    this.usedDataSub = this.user.get().subscribe((res) => {
      this.userDetails = res;
      if (this.userDetails && this.userDetails !== null) {
        this.plpService.getCarts(this.userDetails?.userName);
      }
    });

    this.route.url.subscribe((res) => {
      if (res) {
        this.searchInput = res[1];
      }
    });
  }
  sortList(event): void {
    this.productListService.sort(event);
  }
  performPagination(data): void {
    const event = Number(data);
    this.currentPage = event + 1;
    const firstRange = event * this.pagesize + 1;
    const secondRange = (event + 1) * this.pagesize;
    const previousValue = this.pagination.totalResults - event * this.pagesize;
    if (previousValue < this.pagesize) {
      this.pageRange = `${firstRange}-${previousValue + event * this.pagesize}`;
    } else {
      this.pageRange = `${firstRange}-${secondRange}`;
    }
  }
  populateFaq(): void {
    this.customSearchService.getHelpResults(this.searchInput?.path);
    this.customSearchService.$helpResults.subscribe((res) => {
      this.helpVal = res?.content;
      this.cd.detectChanges();
    });
  }

  childSelectedTab($event: string) {
    $event == 'product'
      ? (this.currentTab = 'product')
      : (this.currentTab = 'help');
    if (this.currentTab == 'help') {
      this.populateFaq();
    }
  }
  ngOnDestroy() {
    this.prodListSub?.unsubscribe();
    this.facetListSub?.unsubscribe();
    this.usedDataSub?.unsubscribe();
  }
}
