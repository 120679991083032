<div class="bc-makePayment-Div">
  <span class="back-To-makePayment">
    <img src="assets/img/chevron-left.svg" alt="left" class="svg-Images">
    <a href="javascript: void(0);" [routerLink]="['/', 'my-account/invoices']" class="blue underline">
      Back to make a payment</a>
  </span>
</div>
<section class="row makePaymentView" id="productList-area">
  <section class="col-md-10 col-sm-9 order-history-right float-right invoice-paymentreview">
    <app-payment-header [tableCategory]="creditInvoice" selectedHeader="review"></app-payment-header>
    <section *ngIf="invoicePaymentList?.length > 0">
      <section class="col-xs-12 col-sm-8 col-md-8 p0">
        <section class="
            col-xs-12 col-sm-12 col-md-12
            p0
            Fields-container
            register-zip
            reviewpayname
          ">
          <label class="Fields-label step2-name">{{ "makeAPayment.nameofPersonAuthorizingPayment" | cxTranslate }}
            <span>{{ personName }}</span>
          </label>
          <label class="Fields-label step2-name">{{ "makeAPayment.EmailforConfirmation" | cxTranslate }}
            <span *ngIf="!isAsmEnabled">{{ userData?.email }}</span>
            <span *ngIf="isAsmEnabled && asmEmailDetails?.selectedEmail !== 'Add Other E-mail Address'">{{
              asmEmailDetails?.selectedEmail }}</span>
            <span *ngIf="isAsmEnabled && asmEmailDetails?.selectedEmail === 'Add Other E-mail Address'">{{
              asmEmailDetails?.newEmail }}</span>
          </label>
        </section>
      </section>
      <section class="col-md-12 tablemain-padding">
        <table aria-describedby="Credits" id="applyCreditsTable" class="payment-table display dataTable no-footer">
          <thead>
            <tr class="heading-table">
              <th id="table1col1" class="sorting" tabindex="0" aria-controls="applyCreditsTable"
                aria-label="Payment-option: activate to sort column ascending">
                {{ "makeAPayment.payment_option" | cxTranslate }}&nbsp;
              </th>
              <th id="table1col2" class="sorting_desc" tabindex="0" aria-controls="applyCreditsTable"
                aria-sort="descending" aria-label="Invoice #: activate to sort column ascending">
                {{ "makeAPayment.invoice#" | cxTranslate }}
              </th>
              <th id="table1col3" class="sorting" tabindex="0" aria-controls="applyCreditsTable"
                aria-label="Due date: activate to sort column ascending">
                {{ "makeAPayment.due_date" | cxTranslate }}
              </th>
              <th id="table1col4" class="pr8 sorting" tabindex="0" aria-controls="applyCreditsTable"
                aria-label="Invoice balance: activate to sort column ascending">
                {{ "makeAPayment.invoice_balance" | cxTranslate }}
              </th>
              <th id="table1col5" class="pr8 sorting_desc" tabindex="0" aria-controls="applyCreditsTable"
                aria-label="Payment amount: activate to sort column ascending">
                {{ "makeAPayment.payment_amount" | cxTranslate }} &nbsp;
              </th>
              <th id="table1col6" class="sorting payment-method" tabindex="0" aria-controls="applyCreditsTable"
                aria-label="Payment method: activate to sort column ascending">
                {{ "makeAPayment.payment_method" | cxTranslate }}
              </th>
              <th id="table1col7" class="sorting" tabindex="0" aria-controls="applyCreditsTable"
                aria-label="Payment date: activate to sort column ascending">
                {{ "makeAPayment.payment_date" | cxTranslate }}
              </th>
            </tr>
          </thead>
          <tbody class="datatable-row popup-comp">
            <tr class="odd" *ngFor="let data of invoicePaymentList">
              <td headers="table1col1">{{ "makeAPayment.pay_now" | cxTranslate }}</td>
              <td headers="table1col2" class="darkblue sorting_1">
                <a *ngIf="data?.docType === 'RV' || data?.docType === 'RD' || data?.docType === 'RF'"
                  href="javascript: void(0);" class="blue underline" (click)="invoicePdf(data?.refDocNo)">
                  {{ data?.refDocNo }}
                </a>
                <a *ngIf="data?.docType === 'DZ' && data?.allocNmbr?.startsWith('7')" href="javascript: void(0);"
                  class="blue underline" (click)="invoicePdf(data?.allocNmbr)">
                  {{ data?.allocNmbr }}
                </a>
                <span *ngIf="data?.docType === 'DR' || data?.docType === 'DG' || data?.docType === 'DY'">
                  {{ data?.refDocNo }}
                </span>
                <span *ngIf="data?.docType === 'DZ' && !data?.allocNmbr?.startsWith('7')">
                  {{ data?.allocNmbr }}
                </span>
                <span *ngIf="data?.docType === 'YR'">
                  {{ data?.refhdr }}
                </span>
                <input id="invoiceDatas0.credit" name="invoiceDatas[0].credit" type="hidden" value="92258122"
                  data-di-id="#invoiceDatas0.credit" />
              </td>
              <td headers="table1col3">{{ data?.dueDate }}</td>
              <td headers="table1col4" class="text-right">
                {{ data?.invoiceBalance | currency }}
                <input id="invoiceDatas0.invoiceBalance" name="invoiceDatas[0].invoiceBalance" value="1479.49"
                  type="hidden" data-di-id="#invoiceDatas0.invoiceBalance" />
              </td>

              <td headers="table1col5" class="text-right">
                {{ data?.paymentAmount }}
              </td>
              <td headers="table1col6">
                <img src="../../../assets/icons/visa.png" class="card-type"
                  *ngIf="selectedCardDetails?.cardType === 'VISA'" alt="VISA" />
                <img src="../../../assets/icons/mastercard.png" class="card-type" alt="MASTER"
                  *ngIf="selectedCardDetails?.cardType === 'MC'" />
                #{{ dispCardNumber?.cardNumber }}
              </td>
              <td headers="table1col7">
                {{ paymentDateDefault | date: "dd/MM/yyyy" }}
              </td>
            </tr>
          </tbody>
        </table>

        <section class="credits-table-2column">
          <div class="row credits-row-rwd" *ngFor="let data of invoicePaymentList">
            <div class="column creadits-header-columnrwd">
              <section>
                <p id="table1col1" class="sorting" tabindex="0" aria-controls="applyCreditsTable"
                  aria-label="Payment-option: activate to sort column ascending">
                  {{ "makeAPayment.payment_option" | cxTranslate }}
                </p>
                <p id="table1col2" class="sorting_desc" tabindex="0" aria-controls="applyCreditsTable"
                  aria-sort="descending" aria-label="Invoice #: activate to sort column ascending">
                  {{ "makeAPayment.invoice#" | cxTranslate }}
                </p>
                <p id="table1col3" class="sorting" tabindex="0" aria-controls="applyCreditsTable"
                  aria-label="Due date: activate to sort column ascending">
                  {{ "makeAPayment.due_date" | cxTranslate }}
                </p>
                <p id="table1col4" class="pr8 sorting" tabindex="0" aria-controls="applyCreditsTable"
                  aria-label="Invoice balance: activate to sort column ascending">
                  {{ "makeAPayment.invoice_balance" | cxTranslate }}
                </p>
                <p id="table1col5" class="pr8 sorting_desc" tabindex="0" aria-controls="applyCreditsTable"
                  aria-label="Payment amount: activate to sort column ascending">
                  {{ "makeAPayment.payment_amount" | cxTranslate }}
                </p>
                <p id="table1col6" class="sorting payment-method" tabindex="0" aria-controls="applyCreditsTable"
                  aria-label="Payment method: activate to sort column ascending">
                  {{ "makeAPayment.payment_method" | cxTranslate }}
                </p>
                <p id="table1col7" class="sorting" tabindex="0" aria-controls="applyCreditsTable"
                  aria-label="Payment date: activate to sort column ascending">
                  {{ "makeAPayment.payment_date" | cxTranslate }}
                </p>
              </section>
            </div>
            <div class="column credits-desc-column">
              <section>
                <p headers="table1col1">{{ "makeAPayment.pay_now" | cxTranslate }}</p>
                <p headers="table1col2" class="darkblue sorting_1">
                  <a *ngIf="data?.docType === 'RV' || data?.docType === 'RD' || data?.docType === 'RF'"
                    href="javascript: void(0);" class="blue underline" (click)="invoicePdf(data?.refDocNo)">
                    {{ data?.refDocNo }}
                  </a>
                  <a *ngIf="data?.docType === 'DZ' && data?.allocNmbr?.startsWith('7')" href="javascript: void(0);"
                    class="blue underline" (click)="invoicePdf(data?.allocNmbr)">
                    {{ data?.allocNmbr }}
                  </a>
                  <span *ngIf="data?.docType === 'DR' || data?.docType === 'DG' || data?.docType === 'DY'">
                    {{ data?.refDocNo }}
                  </span>
                  <span *ngIf="data?.docType === 'DZ' && !data?.allocNmbr?.startsWith('7')">
                    {{ data?.allocNmbr }}
                  </span>
                  <span *ngIf="data?.docType === 'YR'">
                    {{ data?.refhdr }}
                  </span>
                  <input id="invoiceDatas0.credit" name="invoiceDatas[0].credit" type="hidden" value="92258122"
                    data-di-id="#invoiceDatas0.credit" />
                </p>
                <p headers="table1col3">{{ data?.dueDate }}</p>
                <p headers="table1col4">
                  {{ data?.invoiceBalance | currency }}
                  <input id="invoiceDatas0.invoiceBalance" name="invoiceDatas[0].invoiceBalance" value="1479.49"
                    type="hidden" data-di-id="#invoiceDatas0.invoiceBalance" />
                </p>

                <p headers="table1col5" class="mb20">
                  {{ data?.paymentAmount }}
                </p>
                <p headers="table1col6" class="mb20">
                  <img src="../../../assets/icons/visa.png" class="card-type"
                    *ngIf="selectedCardDetails?.cardType === 'VISA'" alt="VISA" />
                  <img src="../../../assets/icons/mastercard.png" class="card-type" alt="MASTER"
                    *ngIf="selectedCardDetails?.cardType === 'MC'" />
                  #{{ dispCardNumber?.cardNumber }}
                </p>
                <p headers="table1col7">
                  {{ paymentDateDefault | date: "dd/MM/yyyy" }}
                </p>
              </section>
            </div>
          </div>
        </section>
      </section>

      <section class="row online-invoices sec-part float-right">
        <aside aria-label="Pay Now" class="col-xs-12 float-right po-right open_aside">
          <section class="row row-20">
            <section class="col-xs-6 col-md-6">
              <h3 class="title">
                {{ "makeAPayment.pay_now" | cxTranslate }}
              </h3>
            </section>
            <section class="col-xs-6 col-md-6">
              <p class="total amount">{{ totalInvoiceAmount | currency }}</p>
            </section>
          </section>
          <p class="para">
            {{ "makeAPayment.totalamountinfo" | cxTranslate }}
          </p>
          <section class="row paytotal">
            <section class="col-xs-6 col-md-6 pl0">
              <h3 class="title bold">
                {{ "makeAPayment.payment_total" | cxTranslate }}
              </h3>
            </section>
            <section class="col-xs-6 col-md-6 pr0">
              <p class="total bold">{{ totalInvoiceAmount | currency }}</p>
            </section>
          </section>
          <button type="submit" class="reviewbtn" data-di-id="di-id-5896ea52-8b55cbae" (click)="submitPayment()"
            [disabled]="invoiceSubmitFlag">
            <i class="fa fa-spinner fa-spin" *ngIf="invoiceSubmitFlag"></i>
            {{ "makeAPayment.submitPayment" | cxTranslate }}
          </button>
          <a href="javascript:void(0)" role="presentation" data-di-id="di-id-7ee5cf4b-3449b0d5">
            <button type="button" (click)="back()" class="cancelbtn">
              {{ "makeAPayment.cancel" | cxTranslate }}
            </button></a>
        </aside>
      </section>
    </section>

    <!-- Credit Review Table -->
    <section *ngIf="creditPaymentList.length > 0">
      <section class="col-xs-12 col-sm-8 col-md-8 p0 p10">
        <section class="
            col-xs-12 col-sm-12 col-md-12
            p0
            Fields-container
            register-zip
            reviewpayname
          ">
          <label class="Fields-label step2-name">{{ "makeAPayment.nameofPersonAuthorizingPayment" | cxTranslate
            }}<span>{{ personName }}</span>
          </label>
          <label class="Fields-label step2-name">{{ "makeAPayment.EmailforConfirmation" | cxTranslate }}
            <span *ngIf="!isAsmEnabled">{{ userData?.email }}</span>
            <span *ngIf="isAsmEnabled && asmEmailDetails?.selectedEmail !== 'Add Other E-mail Address'">{{
              asmEmailDetails?.selectedEmail }}</span>
            <span *ngIf="isAsmEnabled && asmEmailDetails?.selectedEmail === 'Add Other E-mail Address'">{{
              asmEmailDetails?.newEmail }}</span>
          </label>
        </section>
      </section>
      <section class="col-md-12 tablemain-padding sec-part">
        <table aria-describedby="Invoices" id="applyCreditsTable" class="credit-table display dataTable no-footer">
          <thead>
            <tr class="heading-table">
              <th id="table1col1" class="sorting" tabindex="0" aria-controls="applyCreditsTable"
                aria-label="Payment-option: activate to sort column ascending">
                {{ "makeAPayment.credit#" | cxTranslate }} &nbsp;
              </th>
              <th id="table1col2" class="sorting_desc" tabindex="0" aria-controls="applyCreditsTable"
                aria-sort="descending" aria-label="Invoice #: activate to sort column ascending">
                {{ "makeAPayment.apply_to_invoice" | cxTranslate }} &nbsp;
              </th>
              <th id="table1col3" class="pr8 sorting" tabindex="0" aria-controls="applyCreditsTable"
                aria-label="Due date: activate to sort column ascending">
                {{ "makeAPayment.credit_applied" | cxTranslate }}
              </th>
              <th id="table1col4" class="pr8 sorting" tabindex="0" aria-controls="applyCreditsTable"
                aria-label="Invoice balance: activate to sort column ascending">
                {{ "makeAPayment.credit_balance" | cxTranslate }}
              </th>
              <th id="table1col5" class="pr8 sorting" tabindex="0" aria-controls="applyCreditsTable"
                aria-label="Invoice balance: activate to sort column ascending">
                {{ "makeAPayment.invoice_balance" | cxTranslate }} &nbsp;
              </th>
              <th id="table1col6" class="sorting" tabindex="0" aria-controls="applyCreditsTable"
                aria-label="Payment method: activate to sort column ascending">
                {{ "makeAPayment.credit_applied_date" | cxTranslate }}
              </th>
            </tr>
          </thead>

          <tbody class="datatable-row popup-comp">
            <tr class="odd" *ngFor="let data of creditPaymentList">
              <td headers="table1col1" class="sorting_1">
                <a *ngIf="data?.docType === 'RV' || data?.docType === 'RD' || data?.docType === 'RF'"
                  href="javascript: void(0);" class="underline" (click)="creditPdf(data?.refDocNo)">
                  {{ data?.refDocNo }}
                </a>
                <a *ngIf="data?.docType === 'DZ' && data?.allocNmbr?.startsWith('7')" href="javascript: void(0);"
                  class="underline" (click)="creditPdf(data?.allocNmbr)">
                  {{ data?.allocNmbr }}
                </a>
                <span *ngIf="data?.docType === 'DR' || data?.docType === 'DG' || data?.docType === 'DY'">
                  {{ data?.refDocNo }}
                </span>
                <span *ngIf="data?.docType === 'DZ' && !data?.allocNmbr?.startsWith('7')">
                  {{ data?.allocNmbr }}
                </span>
                <span *ngIf="data?.docType === 'YR'">
                  {{ data?.refhdr }}
                </span>
              </td>
              <td headers="table1col2">{{ data?.applyToInvoice }}</td>
              <td headers="table1col3" class="right-text">
                {{ data?.creditsApplied | currency }}
              </td>
              <td headers="table1col4" class="right-text">
                {{ data?.creditBalance | currency }}
              </td>
              <td headers="table1col5" class="right-text">
                {{ data?.invoiceBalance | currency }}
              </td>
              <td headers="table1col6">
                {{ paymentDateDefault | date: "dd/MM/yyyy" }}
              </td>
            </tr>
          </tbody>
        </table>

        <section class="invoicepayment-table-2column">
          <div class="row invoice-2col-row-rwd" *ngFor="let data of creditPaymentList">
            <div class="column invoice-header-rwd">
              <section>
                <p id="table1col1" class="sorting" tabindex="0" aria-controls="applyCreditsTable"
                  aria-label="Payment-option: activate to sort column ascending">
                  {{ "makeAPayment.credit#" | cxTranslate }};
                </p>
                <p id="table1col2" class="sorting_desc" tabindex="0" aria-controls="applyCreditsTable"
                  aria-sort="descending" aria-label="Invoice #: activate to sort column ascending">
                  {{ "makeAPayment.apply_to_invoice" | cxTranslate }};
                </p>
                <p id="table1col3" class="pr8 sorting" tabindex="0" aria-controls="applyCreditsTable"
                  aria-label="Due date: activate to sort column ascending">
                  {{ "makeAPayment.credit_applied" | cxTranslate }}
                </p>
                <p id="table1col4" class="pr8 sorting" tabindex="0" aria-controls="applyCreditsTable"
                  aria-label="Invoice balance: activate to sort column ascending">
                  {{ "makeAPayment.credit_balance" | cxTranslate }}
                </p>
                <p id="table1col5" class="pr8 sorting" tabindex="0" aria-controls="applyCreditsTable"
                  aria-label="Invoice balance: activate to sort column ascending">
                  {{ "makeAPayment.invoice_balance" | cxTranslate }};
                </p>
                <p id="table1col6" class="sorting" tabindex="0" aria-controls="applyCreditsTable"
                  aria-label="Payment method: activate to sort column ascending">
                  {{ "makeAPayment.credit_applied_date" | cxTranslate }}
                </p>
              </section>
            </div>
            <div class="column invoice-desc-column">
              <section>
                <p headers="table1col1" class="sorting_1">
                  <a *ngIf="data?.docType === 'RV' || data?.docType === 'RD' || data?.docType === 'RF'"
                    href="javascript: void(0);" class="underline" (click)="creditPdf(data?.refDocNo)">
                    {{ data?.refDocNo }}
                  </a>
                  <a *ngIf="data?.docType === 'DZ' && data?.allocNmbr?.startsWith('7')" href="javascript: void(0);"
                    class="underline" (click)="creditPdf(data?.allocNmbr)">
                    {{ data?.allocNmbr }}
                  </a>
                  <span *ngIf="data?.docType === 'DR' || data?.docType === 'DG' || data?.docType === 'DY'">
                    {{ data?.refDocNo }}
                  </span>
                  <span *ngIf="data?.docType === 'DZ' && !data?.allocNmbr?.startsWith('7')">
                    {{ data?.allocNmbr }}
                  </span>
                  <span *ngIf="data?.docType === 'YR'">
                    {{ data?.refhdr }}
                  </span>
                </p>
                <p headers="table1col2" class="table1col2">{{ data?.applyToInvoice }}</p>
                <p headers="table1col3" class="right-text">
                  {{ data?.creditsApplied | currency }}
                </p>
                <p headers="table1col4" class="right-text">
                  {{ data?.creditBalance | currency }}
                </p>
                <p headers="table1col5" class="right-text">
                  {{ data?.invoiceBalance | currency }}
                </p>
                <p headers="table1col6">
                  {{ paymentDateDefault | date: "dd/MM/yyyy" }}
                </p>
              </section>
            </div>
          </div>
        </section>

        <section class="invoice-btngrps">
          <a href="javascript:void(0)" class="focusnone" tabindex="-1" data-di-id="di-id-9f8fe96-b6e2fb87">
            <button type="button" (click)="back()" class="btn btn-default oi-white-button">
              {{ "makeAPayment.back_btn" | cxTranslate }}
            </button></a>
            <button type="button" class="btn btn-default oi-blue-button reviewvalidbtn" (click)="applyCredits()"

            [disabled]="creditSubmitFlag" data-di-id="di-id-7a6cb2cd-cb37948a">
            <i class="fa fa-spinner fa-spin" *ngIf="creditSubmitFlag"></i>
            {{ "makeAPayment.credit_title" | cxTranslate }}
          </button>
        </section>
      </section>
    </section>
  </section>
</section>